/**
 * @prettier
 */
import 'core-js/stable'
import 'core-js/proposals/array-find-from-last'
import 'regenerator-runtime/runtime'
import React from 'react' // eslint-disable-line no-restricted-imports
import ReactDOM from 'react-dom'
import $ from 'jquery'
import PT from 'prop-types'
import classNames from 'classnames'
import * as UIkit from 'talkable-ui-kit'
import { filesize } from 'filesize'
import queryString from 'query-string'
import autosize from 'autosize'
import numeral from 'numeral'
import Clipboard from 'clipboard'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import calendar from 'dayjs/plugin/calendar'

import { lodashModules } from 'utils/lodash_modules'
import UINotifications from 'components/shared/notifications/notifications'
import { Validation } from 'legacy/widget/app/validation'
import { documentationURL } from 'utils/etc'
import { utcOffsetFix } from 'utils/dayjs_fix'
// add shared components
import * as Components from 'components/shared/shared'

import globalAjaxEventHandlers from './global_ajax_event_handlers'

window.React = React
window.ReactDOM = ReactDOM
window.jQuery = window.$ = $
window.PT = PT
window.classNames = classNames
window._ = lodashModules
window.UIkit = UIkit
window.filesize = filesize
window.queryString = queryString
window.autosize = autosize
window.numeral = numeral
window.documentationURL = documentationURL
window.Clipboard = Clipboard
window.UINotifications = UINotifications
window.Components = Components
window.Widget = {}
window.Validation = Validation

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(quarterOfYear)
dayjs.extend(customParseFormat)
dayjs.extend(calendar)
dayjs.extend(utcOffsetFix)

window.dayjs = dayjs

// add notifications
UINotifications.mount()

globalAjaxEventHandlers()
